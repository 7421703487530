* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
html,
body,
#root {
    width: 100%;
    height: 100%;
}
.css-1fewj3v {
    margin: 0 !important ;
}
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: rgb(236, 236, 236);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(34, 34, 34, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(39, 39, 39, 0.5);
}
a {
    text-decoration: none;
    color: inherit;
}
